import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProfileImage from "../components/profile-photo"
import BackgroundAnimation from "../components/main-background.js"
import AssImage from "../components/name-img"

import "../style/normalize.css"
import "../style/all.scss"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <BackgroundAnimation/>
    <div style={{ maxWidth: `500px`, textAlign: `center`, verticalAlign: `center`, margin: `5rem auto 1.45rem auto`, paddingTop: `3rem`}}>
      <div style={{ maxWidth: `250px`, horizontalAlign: `center`, verticalAlign: `center`, margin: `auto auto auto auto`}}>
        <ProfileImage/>
      </div>
      <div style={{ maxWidth: `300px`, paddingTop: `1rem`, horizontalAlign: `center`, verticalAlign: `center`, margin: `auto auto auto auto`}}>
        <AssImage/>
      </div>
      <p style={{paddingTop: `100px`, width: `500px`}}>Learning nature, one model at a time</p>
      <p>I am an ecology Ph.D. candidate at <a href="https://posecologia.ib.usp.br/en/">USP</a> under the supervision of <a href="https://www.rmtzgarcia.com/">Ricardo Martínez-García</a>.</p>
    </div>
  </Layout>
)

export default IndexPage
