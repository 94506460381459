import { withAssetPrefix } from "gatsby";
import React from "react"
import backgroundStyles from "./main-background.module.css"

let c = 45;

function draw(){
  document.documentElement.style.setProperty('--direction', c++/4 + 'deg');
  window.requestAnimationFrame(draw);
}

if (typeof window !== `undefined`) {
  window.requestAnimationFrame(draw);
}
// window.requestAnimationFrame(draw);

const BackgroundAnimation = () => {
    return (
        <>
        <div className={backgroundStyles.background}></div>
        <div className={backgroundStyles.foreground}></div>
        </>
    )
}

export default BackgroundAnimation